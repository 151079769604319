<template>
<div>
    <v-icon v-if="document.isFavorite===false">mdi-star-outline</v-icon>
    <v-icon v-if="document.isFavorite===true">mdi-star</v-icon>
</div>
  
</template>

<script>
export default {
  name: "ToggleFavorite",
  data: () => ({}),
  props: {
      document: Object
  },
  computed: {
    // role() {
    //   return this.$store.state.user.role;
    // },
  },
};
</script>
<template>
  <v-container fluid class="primary" style="overflow-y: scroll">
    <v-row class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-6">
          <v-card-title>Announcements</v-card-title>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify" 
            label="Search"
            outlined
            dense
            clearable
            style='position: absolute; top: 7px; right: 7px; width: 220px;'
          ></v-text-field>

          <v-card-subtitle v-if="role === 'admin' || role === 'corporate'">
            <v-switch v-model="showArchives" inset>
              <template v-slot:label>
                Show Archives <v-icon small class="ml-2">mdi-archive</v-icon>
              </template>
            </v-switch>
          </v-card-subtitle>
          <v-data-table
            :headers="headers"
            :items="compBulletins"
            hide-default-footer 
            disable-pagination
          >
            <template v-slot:item.title="{ item }">
              <v-icon small color="red" v-if="item.isUrgent"
                >mdi-information</v-icon
              >
              <v-icon small v-if="item.isArchived">mdi-archive</v-icon>
              {{ item.title }}
            </template>
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:item.id="{ item }">
              <bulletin-modal
                :content="item"
                @restore="restoreArchive($event)"
              />
            </template>
          </v-data-table>
        </v-card>

        <v-card elevation="1" v-if="role === 'admin' || role === 'corporate'">
          <v-card-title>Announcements Management System</v-card-title>
          <v-card-subtitle
            >Administrators and Corporate Users may add, edit and delete
            announcements.</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col class="col col-3">
                <label for=""
                  >Which announcements would you like to edit?</label
                >
                <v-select
                  v-model="bulletin"
                  :items="compBulletins"
                  item-text="title"
                  item-value="_id"
                ></v-select>
              </v-col>
              <v-col class="col col-3">
                <v-btn
                  class="my-6"
                  color="indigo"
                  text
                  block
                  @click="
                    initAddBulletin();
                    showEditor = true;
                  "
                >
                  Add a new announcement
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="showEditor" class="ml-6">
      <v-col class="col col-9">
        <v-card elevation="1" class="ml-6 mb-12">
          <v-card-title>Editor</v-card-title>
          <v-card-text>
            <quill-editor ref="myQuillEditor" v-model="content" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col col-3">
        <v-card elevation="1" class="mb-12">
          <v-card-title>Options</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="title"
                :rules="[rules.required]"
                label="Title of Announcement"
                required
              >
              </v-text-field>

              <label for="">Category</label>
              <v-select v-model="category" :items="compCategories"></v-select>

              <label for="visibleRole">Visibility</label>
              <v-checkbox
                v-model="visibleRole"
                label="All"
                value="All"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="visibleRole"
                v-for="role in roles"
                v-bind:key="role"
                :label="role"
                :value="role"
                hide-details
              ></v-checkbox>
              <br />

              <v-checkbox v-model="bulletinIsUrgent" label="Mark as urgent?">
              </v-checkbox>

              <v-checkbox
                v-if="editMode === 'add'"
                v-model="bulletinIsBroadcast"
                label="Send immediate email/SMS?"
              >
              </v-checkbox>
            </v-form>
            <v-btn
              class="my-6"
              color="indigo"
              outlined
              block
              @click="saveBulletin()"
            >
              Save
            </v-btn>
            <v-btn
              v-if="
                (role === 'admin' || role === 'corporate') &&
                  editMode === 'edit'
              "
              class="my-6"
              color="indigo"
              outlined
              block
              @click="archiveBulletin()"
            >
              Archive
            </v-btn>
            <v-btn
              v-if="(role === 'admin' || role === 'corporate') && isArchived"
              class="my-6"
              color="indigo"
              outlined
              block
              @click="restoreArchive(bulletin)"
            >
              Restore
            </v-btn>
            <v-btn
              v-if="role === 'admin' && editMode === 'edit'"
              class="my-6"
              color="red"
              outlined
              block
              @click="showConfirm = true"
            >
              Delete
            </v-btn>
            <div v-if="showConfirm">
              Are you sure?
              <v-btn
                class="my-6"
                color="red"
                text
                block
                @click="deleteBulletin()"
              >
                Yes
              </v-btn>
              <v-btn
                class="my-6"
                color="indigo"
                text
                block
                @click="showConfirm = false"
              >
                Nevermind
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from "@/plugins/helpers";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import moment from "moment";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import ToggleFavorite from "@/components/ToggleFavorite.vue";
import BulletinModal from "@/components/BulletinModal";
export default {
  name: "Bulletins",
  components: {
    ToggleFavorite,
    BulletinModal,
    quillEditor
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Title", value: "title", width: "40%" },
      { text: "Category", value: "category", width: "30%" },
      { text: "Date", value: "createdOn" },
      { text: "Added By", value: "author" },
      { text: "", align: "end", value: "id", filterable: false }
    ],
    valid: true,
    rules: {
      required: value => !!value || "Required."
    },
    title: "",
    bulletin: "",
    bulletinIsUrgent: false,
    bulletinIsBroadcast: false,
    showEditor: false,
    showConfirm: false,
    content: "",
    sort: 0,
    editMode: "add",
    dateRange: "",
    dateRanges: [
      "Last 7 Days",
      "Last 30 Days",
      "Last 6 Months",
      "Last 12 Months"
    ],
    url: "",
    author: "",
    category: "",
    visibleRole: ["All"],
    users: [],
    showArchives: false,
    isArchived: false
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    roles() {
      let users = this.users;
      const unique = [...new Set(users.map(item => item.role))];
      return unique;
    },
    compBulletins() {
      let data = this.$store.state.bulletins;
      // removed archived bulletins if show archives is false
      if (this.showArchives === false) {
        data = data.filter(d => d.isArchived === false);
      }

      // optional complete text search
      if(this.search) data = data.filter(d => this.textSearch(d) )

      // sort by date desc
      data.sort((a, b) => {
        let da = new Date(a.createdOn),
          db = new Date(b.createdOn);
        return db - da;
      });
      // move isUrgent to the top of the list
      data.sort((a, b) => {
        return b.isUrgent - a.isUrgent;
      });

      return data;
    },
    compCategories() {
      let data = this.$store.state.documents;
      let unique = [...new Set(data.map(item => item.category))];
      return unique.sort();
    },
    compAuthors() {
      let documents = this.$store.state.documents;
      let unique = [...new Set(documents.map(item => item.author))];
      unique.sort();
      return unique;
    }
  },
  methods: {
  textSearch(item) {
    if (item.content.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) return true;
    if (item.title.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) return true;
    if (item.category.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) return true;
    if (item.author.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) return true;
  },
    initAddBulletin() {
      this._id = "";
      this.showEditor = false;
      this.editMode = "add";
      this.title = "";
      this.bulletin = "";
      this.width = 12;
      this.visibleRole = ["All"];
      this.content = "";
      this.showConfirm = false;
      this.category = "";
      this.bulletinIsUrgent = false;
      this.isArchived = false;
    },
    saveBulletin() {
      let vm = this;
      if (this.$refs.form.validate()) {
        if (vm.editMode === "add") {
          let bulletin = {
            _id: this.getId(),
            title: this.title,
            content: this.content,
            roles: this.visibleRole,
            author: this.$store.state.user.firstname,
            category: this.category,
            isUrgent: this.bulletinIsUrgent,
            isBroadcast: this.bulletinIsBroadcast,
            createdOn: moment().format(),
            isArchived: false
          };
          axios
            .post(vm.$root.urls.api + "/bulletin", {
              bulletin
            })
            .then(function(results) {
              vm.$toasted.show("Announcement added").goAway(2500);
              vm.$store.commit("addBulletin", {
                bulletin: results.data
              });
              if (vm.bulletinIsBroadcast) {
                vm.sendBroadcast();
              }
              vm.initAddBulletin();
            })
            .catch(function(error) {
              vm.$toasted
                .show("Unable to save. Please remove any large images.", {
                  type: "error",
                  icon: "mdi-alert-circle"
                })
                .goAway(2500);
            });
        } else {
          let bulletin = {
            _id: this._id,
            title: this.title,
            content: this.content,
            roles: this.visibleRole,
            author: this.$store.state.user.firstname,
            category: this.category,
            isUrgent: this.bulletinIsUrgent,
            isBroadcast: this.bulletinIsBroadcast,
            isArchived: false
          };
          axios
            .put(vm.$root.urls.api + "/bulletin", {
              bulletin
            })
            .then(function(results) {
              // toast
              vm.$toasted.show("Announcement updated").goAway(2500);
              vm.$store.commit("updateBulletin", {
                bulletin
              });
              if (vm.bulletinIsBroadcast) {
                vm.sendBroadcast();
              }
              vm.initAddBulletin();
            })
            .catch(function(error) {
              vm.$toasted
                .show("Unable to save. Please remove any large images.", {
                  type: "error",
                  icon: "mdi-alert-circle"
                })
                .goAway(2500);
            });
        }
      }
    },
    archiveBulletin() {
      let vm = this;
      let bulletin = {
        _id: this._id,
        title: this.title,
        content: this.content,
        roles: this.visibleRole,
        author: this.$store.state.user.firstname,
        category: this.category,
        isUrgent: this.bulletinIsUrgent,
        isBroadcast: this.bulletinIsBroadcast,
        isArchived: true
      };
      axios
        .put(vm.$root.urls.api + "/bulletin", {
          bulletin
        })
        .then(function(results) {
          // toast
          vm.$toasted.show("Announcement archived").goAway(2500);
          vm.$store.commit("updateBulletin", {
            bulletin
          });
          // if (vm.bulletinIsBroadcast) {
          //   vm.sendBroadcast();
          // }
          vm.initAddBulletin();
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to archive.", {
              type: "error",
              icon: "mdi-alert-circle"
            })
            .goAway(2500);
        });
    },
    restoreArchive(id) {
      let vm = this;
      // prepare bulletin
      let bulletin = vm.compBulletins.filter(b => b._id === id);
      bulletin[0].isArchived = false;
      bulletin = bulletin[0];
      axios
        .put(vm.$root.urls.api + "/bulletin", {
          bulletin
        })
        .then(function(results) {
          // toast
          vm.$toasted.show("Announcement restored").goAway(2500);
          vm.$store.commit("updateBulletin", {
            bulletin
          });
        })
        .catch(function(error) {
          vm.$toasted
            .show("Unable to restore", {
              type: "error",
              icon: "mdi-alert-circle"
            })
            .goAway(2500);
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    getId() {
      return Math.floor(Math.random() * 10000 + 1000);
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;
      return today;
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    },
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
      this.content = html;
      //   this.$store.commit("updateDashboard", {
      //     dashboard: html,
      //   });
    },
    deleteBulletin() {
      let vm = this;
      // console.log("gonna delete an existing panel");
      let bulletin = {
        _id: this._id
      };
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .delete(vm.$root.urls.api + "/bulletin", {
          data: {
            bulletin
          }
        })
        .then(function(results) {
          vm.$store.commit("deleteBulletin", {
            bulletin
          });
          // toast
          vm.$toasted.show("Announcement deleted").goAway(2500);
          vm.initAddBulletin();
        });
    },
    sendBroadcast() {
      let vm = this;
      let message = vm.content;
      const email = "";
      const phone = "";
      const recipients = vm.visibleRole;
      const author = vm.$store.state.user.firstname;

      // add title, category and author to message
      message = `<p>${vm.title} | ${vm.category} | added by ${author}</p> ${message}`;

      // ensure at least one role has been selected
      if (recipients.length) {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios
          .post(vm.$root.urls.api + "/notification/manual", {
            message,
            email,
            phone,
            recipients
          })
          .then(function(results) {
            vm.$toasted.show("Manual notification sent").goAway(2500);
          });
      } else {
        vm.$toasted
          .show("Please select at least one recipient", {
            type: "error",
            icon: "mdi-alert-circle"
          })
          .goAway(2500);
      }
    }
  },
  mounted: function() {
    let vm = this;
    // query api for users if admin or corporate
    if (vm.role === "admin" || vm.role === "corporate") {
      axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
      axios.get(vm.$root.urls.api + "/users", {}).then(function(results) {
        vm.users = results.data;
      });
    }
  },
  watch: {
    bulletin(val) {
      if (val) {
        let bulletin = this.compBulletins.filter(c => c._id === val);
        this._id = bulletin[0]._id;
        this.title = bulletin[0].title;
        this.content = bulletin[0].content;
        this.category = bulletin[0].category;
        this.bulletinIsUrgent = bulletin[0].isUrgent;
        this.editMode = "edit";
        this.showEditor = true;
        this.isArchived = bulletin[0].isArchived;
      }
    }
  }
};
</script>
